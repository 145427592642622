import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./admissionform.css";
import API from "../../common/api";
import { ADMISSION_FORM } from "../../common/constants";
import { vaccinationData } from "../../data/vaccinationData";
import { useNavigate } from "react-router-dom";

export default function Admissionform() {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [street1, setStreet1] = useState("");
  const [street2, setStreet2] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [emg_contact, setEmgContact] = useState("");
  const [emg_contact_no, setEmgContactNo] = useState("");
  const [doctor_name, setDoctorName] = useState("");
  const [doctor_number, setDoctorNumber] = useState("");
  const [copySchedule, setCopySchedule] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [checkedState, setCheckedState] = useState({});

  //   dog Year
  const [selected, setSelected] = useState();
  //   dog Month
  // const [items, setItems] = useState();
  const [dogMonth, setDogMonth] = useState("");
  // const [selectedAge, setSelectedAge] = useState("");

  //   dogBreed
  const [dogBreed, setDogBreed] = useState("");
  const [dogActivity, setDogActivity] = useState("");

  // states
  const [dogNameError, setDogNameError] = useState("");
  const [breedError, setBreedError] = useState("");
  const [breakfastError, setBreakfastError] = useState("");
  const [dinnerError, setDinnerError] = useState("");
  const [spayedError, setSpayedError] = useState("");
  const [firstNameError, setFirstNameError] = useState();
  const [lastNameError, setLastNameError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [doctorNameError, setDoctorNameError] = useState("");
  const [doctorNumberError, setDoctorNumberError] = useState("");
  const [emgContactError, setEmgContactError] = useState("");
  const [emgContactNumberError, setEmgContactNumberError] = useState("");

  const [forms, setForms] = useState([
    {
      id: 1,
      dog_name: "",
      breed: "",
      mobile: mobile,
      age_year: "0 Year",
      age_month: "",
      gender: "",
      breakfast: "",
      dinner: "",
      snacks: "",
      lunch: "",
      food_instruction: "",
      spayed: null,
      vaccination: [],
      instruction: "",
      aggression: null,
      bitten_person: null,
      aggression_instruction: "",
      allergic_instruction: "",
      boarding: null,
      boarding_facilty: "",
      // doctor_number: '',
      // doctor_name: '',
      // emg_contact: '',
      // emg_contact_no: '',
      activity_level: "",
      eat_from_road: null,
      pull_walking: null,
      intentionally_come_walk: null,
      extra_instruction: "",
      medical_instruction: "",
    },
  ]);

  // cloning form
  const addForm = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    const newForm = { id: forms.length + 1 };
    setForms([...forms, newForm]);
    setTimeout(() => {
      handleClickScroll(forms.length + 1);
    }, 0);
    setCheckedState((prevState) => ({
      ...prevState,
      [forms.length + 1]: false // Initialize the new id with unchecked state
    }));
  };

  const handleClickScroll = (id) => {
    const element = document.getElementById(`form-section${id}`);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "auto", block: "start" });
    }
  };

  const removeForm = (id) => {
    const updatedForms = forms.filter((form) => form.id !== id);
    setForms(updatedForms);
  };

  // Breed Select
  const getBreed = () => {
    var myHeaders = new Headers();

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://admin.petfelix.in/api/get-breed", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const breedData = result?.data.map((item) => {
          return {
            value: item.id,
            label: item.breed_name,
          };
        });
        setDogBreed(breedData);
      })
      .catch((error) => console.log("error", error));
  };

  //Age Year
  const getAgeYear = () => {
    var myHeaders = new Headers();

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://admin.petfelix.in/api/get-age-year", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const AgeData = result?.data.map((item) => {
          return {
            value: item.name,
            label: item.name,
          };
        });
        setSelected(AgeData);
      })
      .catch((error) => console.log("error", error));
  };

  //Age Month
  const getAgeMonth = () => {
    var myHeaders = new Headers();

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://admin.petfelix.in/api/get-age-month", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const AgeMonthData = result?.data.map((item) => {
          return {
            value: item.name,
            label: item.name,
          };
        });
        setDogMonth(AgeMonthData);
      })
      .catch((error) => console.log("error", error));
  };

  //Activity Level

  const ActivityLevel = () => {
    var myHeaders = new Headers();

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://admin.petfelix.in/api/get-activity-level", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const ActivityLevelData = result?.data.map((item) => {
          return {
            value: item.name,
            label: item.name,
          };
        });
        setDogActivity(ActivityLevelData);
      })
      .catch((error) => console.log("error", error));
  };

  // boardingFacility

  const boardingFacilityOptions = [
    { value: "home boarding", label: "Home Boarding" },
    { value: "Co-boarding", label: "Co-boarding" },
    { value: "kennel based boarding", label: "kennel based boarding" },
    {
      value: "At a frens/ Relative place",
      label: "At a frens/ Relative place",
    },
  ];

  //   getData Api
  useEffect(() => {
    getBreed();
    getAgeYear();
    getAgeMonth();
    ActivityLevel();
  }, []);

  const handleChange = (id, event) => {
    console.log("ID", event.target.id);
    const name = event.target.id;
    const value = event.target.value;


    if (name === 'age_year' && value === "") {

      setForms((prevData) => ({ ...prevData, [name]: '0 Year' }));
    }

    setForms((prevData) => {
      return prevData.map((obj) => {
        if (obj.id == id) {
          return { ...obj, [name]: value };
        }

        return obj;
      });
    });
  };



  const copyMealValues = (prevData) => {
    return prevData.map((obj, index) => {
      if (index !== 0) {
        return {
          ...obj,
          breakfast: prevData[0].breakfast,
          lunch: prevData[0].lunch,
          dinner: prevData[0].dinner
        };
      }
      return obj;
    });
  };

  const handleCopyMealValues = (id, value) => {
    setForms((prevData) => {
      if (value) {
        return copyMealValues(prevData);
      } else {
        return prevData.map((obj) => {
          if (obj.id === id) {
            // Reset meal values for the specified form
            return {
              ...obj,
              breakfast: '',
              lunch: '',
              dinner: ''
            };
          }
          return obj;
        });
      }
    });
  };

  const handleCheckboxChange = (id, isChecked) => {
    setCheckedState((prevState) => ({
      ...prevState,
      [id]: isChecked
    }));

    setForms((prevData) => {
      if (!isChecked) {
        return resetMealValues(prevData, id);
      }
      return prevData;
    });
  };
  const resetMealValues = (prevData, id) => {
    return prevData.map((obj) => {
      if (obj.id === id) {
        return {
          ...obj,
          breakfast: '',
          lunch: '',
          dinner: ''
        };
      }
      return obj;
    });
  };

  // const handleChangeAge = (id, event) => {
  //   const { name, age_year, age_month, value, item } = event.target;
  //   if (age_year=== selected) {
  //     setForms((prevData) => {
  //       return prevData.map((obj) => {
  //         if (obj.id == id) {
  //           return { ...obj, [name]: value };
  //         }
  //         return obj;
  //       });
  //     });
  //   }
  //   // else if (age_month.value === dogMonth) {
  //   //   setForms((prevData) => {
  //   //     return prevData.map((obj) => {
  //   //       if (obj.id == id) {
  //   //         return { age_year: "0 Year", ...obj, [name]: value };
  //   //       }
  //   //       return obj;
  //   //     });
  //   //   });
  //   //   // setForms((prevData) => {
  //   //   //   return prevData.map((obj) => {
  //   //   //        return { ...obj, age_year:"0 Year", [name]: value }
  //   //   //       });
  //   //   //     });
  //   // }
  //   //  else {
  //   //   // handleChange(item.id, event);
  //   //   setForms((prevData) => {
  //   //   return prevData.map((obj) => {
  //   //     if (obj.id == id) {
  //   //       return { ...obj, [name]: value };
  //   //     }
  //   //     return obj;
  //   //   });
  //   // });
  //   // }
  // };

  // API Call for admission form
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");


      const newForms = forms?.map((item) => {
        return {
          ...item,
          doctor_number: doctor_name,
          doctor_name: doctor_number,
          emg_contact: emg_contact,
          emg_contact_no: emg_contact_no,
        };
      });
      console.log("New Forms", newForms);
      const data = {
        owner_first_name: firstName,
        owner_last_name: lastName,
        mobile: mobile,
        street1: street1,
        street2: street2,
        pincode: pincode,
        city: city,
        dogs: newForms,
      };

      console.log("Data new ", data);

      const response = await API.post(ADMISSION_FORM, data);
      console.log("Response admission form", response);
      if (response?.data?.status == true) {
        navigate("/admission-thankyou");
        // window.location.reload(false);
      } else {
        alert("Unsuccessful!");
        setIsLoading(false);
      }
    } catch (error) {
      console.log("Admission form error", error.response.data.error.mobile);
      alert(String(error.response.data.error.mobile));
      setIsLoading(false);
    }
  };

  const handleCheckBox = (item, index) => {
    const new_item = { ...item };
    console.log("index", index);
    var data = [...vaccinationData];
    new_item["isSelect"] = !item?.isSelect;
    console.log("new_item", new_item);
    if (item?.isSelect) {
    } else {
      for (let i = 0; i < data?.length; i++) {
        if (data[i] == item?.id) {
          data?.splice(i, 1);
        }
      }
    }

    console.log("data", data);

    let newData = data?.filter((e) => e?.isSelect == true);
    console.log("New data", newData);
    let names = newData.map((item) => item.name);
    console.log("names", names);
    console.log("index", index);
    forms[index].vaccination = names;
    setData(names);
    console.log("forms", forms);
  };

  // Render Forms
  const renderForms = () => {
    return forms.map((item, index) => (
      <div
        className="specify-numbers-price"
        key={item.id}
        id={`form-section${item?.id}`}
      >
        <div className="checkout-form light-font border-rad-10">
          <div className="form-booking ">
            <div className="text-center">
              <h2 className="pb-4 f-24 ">Your doggy details</h2>
            </div>

            <div className="col-md-8 offset-md-2 mb-30 mt--10">
              <label htmlFor>
                Dog's Name <span className="text-danger">*</span>
              </label>
              <input
                id="dog_name"
                className="form-control"
                required
                placeholder=""
                name="dog_name"
                value={item.dog_name}
                type="text"
                onChange={(event) => {
                  const name = event.target.value;
                  if (name.trim() === "") {
                    setDogNameError("Please enter the dog's name.");
                  } else {
                    setDogNameError("");
                  }
                  handleChange(item.id, event);
                }}
              />
              {dogNameError !== "" && (
                <div className="text-danger">{dogNameError}</div>
              )}
            </div>

            <div className="col-md-8 offset-md-2 mb-30 mt--10">
              <input
                hidden
                id="mobile"
                className="form-control"
                required
                placeholder
                name={"mobile"}
                value={mobile}
                type="text"
                readOnly
              />
            </div>

            <div className="col-md-8 offset-md-2 mb-30">
              <label htmlFor>
                Breed of the Doggy <span className="text-danger">*</span>{" "}
              </label>
              <div className="select">
                <select
                  id="breed"
                  name="breed"
                  required
                  value={item.breed}
                  onChange={(event) => {
                    const selectedBreed = event.target.value;
                    setBreedError(selectedBreed === "");
                    handleChange(item.id, event);
                  }}
                  style={{ fontSize: "12px" }}
                >
                  <option value="">Select Dog's Breed</option>
                  {dogBreed &&
                    dogBreed?.map((item) => {
                      return (
                        <option value={item.value}>{item?.label}</option>
                      );
                    })}
                </select>
              </div>
              {breedError && <p className="text-orange">Required</p>}
              <p />
            </div>
            {/* ===================select =================== */}

            <div className="col-md-8 offset-md-2 mb-30">
              <label htmlFor>How old is the doggy </label>
              <span className="text-danger">*</span>{" "}
              <div className="select">
                <select
                  id="age_year"
                  autoFocus={true}
                  name="age_year"
                  required='required'
                  // autoComplete={handleChangeAge}
                  // defaultValue={}
                  value={item.age_year}
                  onChange={(event) => handleChange(item.id, event)}
                  style={{ fontSize: "12px" }}
                >
                  {/* <option value={""}>Select Age Year </option>; */}
                  {selected &&
                    selected?.map((item) => {
                      return (
                        <option value={item.value}>{item?.label}</option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="col-md-8 offset-md-2 mb-30">
              <div className="select">
                <select
                  id="age_month"
                  autoFocus={true}
                  // required='required'
                  name={`age_month${item.id}`}

                  value={item.age_month}
                  onChange={(event) => handleChange(item.id, event)}
                  style={{ fontSize: "12px" }}
                >
                  <option value={""}>Select Age Month</option>;
                  {dogMonth &&
                    dogMonth?.map((item) => {
                      return (
                        <option value={item.value}>{item?.label}</option>
                      );
                    })}
                </select>
              </div>
            </div>

            <div className="col-md-8 offset-md-2 mb-30mt-sm-0">
              <label>Gender of the dog</label>
              <br />

              <label>
                <input
                  id="gender"
                  type="radio"
                  checked={item.gender == "male"}
                  name={`gender${item.id}`}
                  value="male"
                  onChange={(event) => handleChange(item.id, event)}
                />
                <span className="radio-class">Male</span>
              </label>
              <label>
                <input
                  id="gender"
                  type="radio"
                  checked={item.gender == "female"}
                  name={`gender${item.id}`}
                  value="female"
                  onChange={(event) => handleChange(item.id, event)}
                />
                <span className="radio-class">Female</span>
              </label>
            </div>

            <div className="col-md-8 offset-md-2 mb-30">
              <h4 className="mt-5 mt-sm-4">Food Schedule</h4>
              {
                forms?.length > 1 ?
                  <div className="row mb-20" style={{ marginTop: 20 }}>

                    <div className="col-md-12 d-flex align-items-center justify-content-start">
                      <input
                        id="copySchedule"
                        type="checkbox"
                        checked={checkedState[item.id] || false}
                        style={{ marginRight: 10 }}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          handleCheckboxChange(item.id, isChecked);
                          setCopySchedule(!copySchedule);
                          handleCopyMealValues(item.id, isChecked)
                        }}
                      />
                      <label htmlFor="copySchedule" style={{ fontStyle: 'italic' }}>Copy schedule from previous dog </label>

                    </div>
                  </div>
                  :
                  null
              }

              <p className="pt-0 mt-0 mb-0 pb-0 text-danger" >
                Please give us a detailed account. Content of meals, quantity
                of meals and timings of meals
              </p>
            </div>

            <div className="col-md-8 offset-md-2 mb-30 mt--10">
              <label htmlFor>
                Breakfast <span style={{fontSize:16}} className="text-danger ">* Mention the quantity of dry food / kibble in grams or handfulls</span> 
              </label>
              <input
                id="breakfast"
                className="form-control"
                required
                placeholder=""
                name="breakfast"
                value={item.breakfast}
                type="text"
                onChange={(event) => {
                  const breakfastValue = event.target.value;
                  setBreakfastError(breakfastValue.trim() === "");
                  handleChange(item.id, event);
                }}
              />
              {breakfastError && <p className="text-orange">Required</p>}
            </div>

            <div className="col-md-8 offset-md-2 mb-30">
              <label htmlFor>
                Lunch                  <span style={{fontSize:16}} className="text-danger"> Mention the quantity of dry food / kibble in grams or handfulls</span> 

              
              </label>
              <input
                id="lunch"
                className="form-control"
                name="lunch"
                value={item.lunch}
                type="text"
                onChange={(event) => handleChange(item.id, event)}
              />
            </div>

            <div className="col-md-8 offset-md-2 mb-30">
              <label htmlFor>
                Dinner <span style={{fontSize:16}} className="text-danger">* Mention the quantity of dry food / kibble in grams or handfulls</span>
              </label>
              <input
                id="dinner"
                className="form-control"
                name="dinner"
                value={item.dinner}
                type="text"
                required
                onChange={(event) => {
                  const dinnerValue = event.target.value;
                  setDinnerError(dinnerValue.trim() === "");
                  handleChange(item.id, event);
                }}
              />
              {dinnerError && <p className="text-orange">Required</p>}
            </div>

            <div className="col-md-8 offset-md-2 mb-30">
              <label htmlFor>Snacks - please mention time </label>
              <input
                id="snacks"
                className="form-control"
                placeholder
                value={item.snacks}
                type="text"
                name="snacks"
                onChange={(event) => handleChange(item.id, event)}
              />
            </div>

            <div className="col-md-8 offset-md-2 mb-30">
              <label htmlFor>Any specific instructions regarding food</label>
              <textarea
                name="food_instruction"
                className="form-control"
                id="food_instruction"
                cols={20}
                rows={2}
                spellCheck="false"
                value={item.food_instruction}
                defaultValue={""}
                type="text"
                onChange={(event) => handleChange(item.id, event)}
              />
            </div>
            <div className="col-md-8 offset-md-2 mb-30">
              <label htmlFor>Is your dog spayed/neutered? </label>
              <br />

              <input
                id="spayed"
                type="radio"
                className="mr-1"
                value={1}
                checked={item?.spayed == 1}
                name={`spayed${item.id}`}
                onChange={(event) => {
                  handleChange(item.id, event);
                }}
              />

              <label className="radio-inline mt-0 radio-class">
                <span>Yes</span>
              </label>

              <input
                id="spayed"
                type="radio"
                className="mr-1"
                value={0}
                checked={item?.spayed == 0}
                name={`spayed${item.id}`}
                onChange={(event) => {
                  handleChange(item.id, event);
                }}
              />

              <label className="radio-inline mt-0 radio-class">
                <span>No</span>
              </label>
            </div>

            {/* ===============Remaining========== */}
            <div className="col-md-8 offset-md-2 mb-30">
              <label htmlFor="txtName" className>
                Vaccinations done - please tick the ones done within the last
                12 months <span className="text-danger" />
              </label>

              {vaccinationData?.map((e) => {
                return (
                  <>
                    <div className="d-flex form-check">
                      <input
                        className="form-check-input"
                        style={{ minWidth: "15px", minHeight: "15px" }}
                        type="checkbox"
                        id="vaccinations"
                        name={`vaccinations${e.id}`}
                        value={e.name}
                        onChange={() => handleCheckBox(e, index)}
                      />
                      <label
                        htmlFor={`custom-checkbox-${e}`}
                        className="form-check-label mx-3"
                        for="flexRadioDefault1"
                      >
                        {e?.name}
                      </label>
                    </div>
                  </>
                );
              })}
            </div>

            <div className="col-md-8 offset-md-2 mb-30">
              <label htmlFor="txtName" className="mt-30">
                Does your dog have any boarding experience?
                <span className="text-danger">*</span>
              </label>
              <br />
              <input
                type="radio"
                className="mr-1"
                value={1}
                checked={item.boarding == 1}
                required
                id="boarding"
                name={`boarding${item.id}`}
                onChange={(event) => handleChange(item.id, event)}
              />

              <label className="radio-inline mt-0 radio-class">Yes</label>

              <input
                type="radio"
                className="mr-1"
                id="boarding"
                name={`boarding${item.id}`}
                value={0}
                checked={item.boarding == 0}
                onChange={(event) => handleChange(item.id, event)}
                required
              />

              <label className="radio-inline">No</label>
            </div>

            <div className="col-md-8 offset-md-2 mb-30">
              <label htmlFor="txtName" className>
                What type of boarding facility was it?
              </label>

              <div className="select">
                <select
                  id="boarding_facilty"
                  autoFocus={true}
                  name={`boarding_facilty${item.id}`}
                  value={item.boarding_facilty}
                  onChange={(event) => handleChange(item.id, event)}
                  style={{ fontSize: "12px" }}
                >
                  <option value={""}>Select Boarding Facility</option>;
                  {boardingFacilityOptions &&
                    boardingFacilityOptions?.map((item) => {
                      return (
                        <option value={item.value}>{item?.label}</option>
                      );
                    })}
                </select>
              </div>
              <p className="text-orange">
                Ignore this question if your answer to the previous question
                was NO
              </p>
            </div>
            <div className="col-md-8 offset-md-2 mb-30">
              <label htmlFor="txtName" className>
                Anything we need to know about the previous boarding
                experience which will help us take better care
              </label>
              <textarea
                name="instruction"
                className="form-control"
                id="instruction"
                cols={30}
                rows={3}
                value={item.instruction}
                onChange={(event) => handleChange(item.id, event)}
              />
            </div>

            <div className="col-md-8 offset-md-2 mb-30">
              <label htmlFor="txtName" className="mt-30">
                Does your dog show aggression towards new people?
                <span className="text-danger">*</span>
              </label>
              <br />
              <input
                type="radio"
                className="mr-1"
                id="aggression"
                name={`aggression${item.id}`}
                value={1}
                checked={item.aggression == 1}
                onChange={(event) => handleChange(item.id, event)}
                required
              />
              <label className="radio-inline radio-class">Yes</label>

              <input
                type="radio"
                className="mr-1"
                id="aggression"
                name={`aggression${item.id}`}
                value={0}
                checked={item.aggression == 0}
                onChange={(event) => handleChange(item.id, event)}
                required
              />
              <label className="radio-inline radio-class">No</label>
            </div>

            <div className="col-md-8 offset-md-2 mb-30">
              <label htmlFor="txtName" className="mt-30">
                Has your dog bitten a person ever?
                <span className="text-danger">*</span>
              </label>
              <br />
              <input
                type="radio"
                className="mr-1"
                id="bitten_person"
                name={`bitten_person${item.id}`}
                value={1}
                checked={item.bitten_person == 1}
                onChange={(event) => handleChange(item.id, event)}
                required
              />
              <label className="radio-inline mt-0 radio-class"> Yes</label>

              <input
                type="radio"
                className="mr-1"
                id="bitten_person"
                name={`bitten_person${item.id}`}
                value={0}
                checked={item.bitten_person == 0}
                onChange={(event) => handleChange(item.id, event)}
                required
              />
              <label className="radio-inline  radio-class">No</label>
            </div>
            <div className="col-md-8 offset-md-2 mb-30">
              <div className>
                <label>
                  Does anything trigger an aggressive reaction in your dog
                  towards people?
                </label>
                <input
                  className="form-control"
                  id="aggression_instruction"
                  name="aggression_instruction"
                  type="text"
                  value={item.aggression_instruction}
                  onChange={(event) => handleChange(item.id, event)}
                  placeholder
                />
                <p className="text-orange">
                  E.g some dogs dont like their paws to be touched...some dont
                  like being leashed. ( Please ignore if not applicable)
                </p>
              </div>
            </div>
            <div className="col-md-8 offset-md-2 mb-30">
              <label htmlFor="txtName" className>
                Is your dog allergic to anything?
              </label>
              <textarea
                className="form-control"
                id="allergic_instruction"
                name="allergic_instruction"
                cols={30}
                value={item.allergic_instruction}
                onChange={(event) => handleChange(item.id, event)}
                rows={3}
                defaultValue={""}
              />
              <p />
            </div>
            <div className="col-md-8 offset-md-2 mb-30">
              <label htmlFor="txtName" className>
                Any medical condition
              </label>
              <textarea
                className="form-control"
                id="medical_instruction"
                name="medical_instruction"
                value={item.medical_instruction}
                onChange={(event) => handleChange(item.id, event)}
                cols={30}
                rows={3}
                defaultValue={""}
              />
            </div>
            <div className="col-md-8 offset-md-2 mb-20" />
            <div className="col-md-8 offset-md-2 mb-30"></div>

            <div className="col-md-8 offset-md-2 mb-20" />
            <div className="col-md-8 offset-md-2 mb-30"></div>

            {/* working============================================ */}
            <div className="col-md-8 offset-md-2 mb-30">
              <label htmlFor="txtName" className>
                Overall activity levels of the dog
              </label>
              <div className="select">
                <select
                  id="activity_level"
                  name={`activity_level${item.id}`}
                  value={item.activity_level}
                  onChange={(event) => handleChange(item.id, event)}
                  autoFocus={true}
                  style={{ fontSize: "12px" }}
                >
                  <option value={""}>Select Activity Level</option>;
                  {dogActivity &&
                    dogActivity?.map((item) => {
                      return (
                        <option value={item.value}>{item?.label}</option>
                      );
                    })}
                </select>
              </div>

              <p />
            </div>
            <div className="col-md-8 offset-md-2 mb-30">
              <label htmlFor="txtName" className="mt-30">
                Does your dog pick up and eat things from the road?
                <span className="text-danger" />
              </label>
              <br />
              <input
                type="radio"
                className="mr-1"
                id="eat_from_road"
                name={`eat_from_road${item.id}`}
                value={1}
                checked={item?.eat_from_road == 1}
                onChange={(event) => handleChange(item.id, event)}
              />

              <label className="radio-inline radio-class">Yes</label>

              <input
                type="radio"
                className="mr-1"
                id="eat_from_road"
                name={`eat_from_road${item.id}`}
                value={0}
                checked={item?.eat_from_road == 0}
                onChange={(event) => handleChange(item.id, event)}
              />

              <label className="radio-inline radio-class">No</label>
            </div>

            <div className="col-md-8 offset-md-2 mb-30">
              <label htmlFor="txtName" className="mt-30">
                Does your dog pull a lot while walking?
              </label>
              <br />
              <input
                type="radio"
                className="mr-1"
                id="pull_walking"
                name={`pull_walking${item.id}`}
                value={1}
                checked={item?.pull_walking == 1}
                onChange={(event) => handleChange(item.id, event)}
              />
              <label className="radio-inline radio-class">Yes</label>

              <input
                type="radio"
                className="mr-1"
                id="pull_walking"
                name={`pull_walking${item.id}`}
                value={0}
                checked={item?.pull_walking == 0}
                onChange={(event) => handleChange(item.id, event)}
              />
              <label className="radio-inline radio-class">No</label>
            </div>
            <div className="col-md-8 offset-md-2 mb-30">
              <label htmlFor="txtName" className="mt-30">
                Has your dog been able to intentionally come free from his/her
                leash while on a walk?
              </label>
              <input
                type="radio"
                className="mr-1"
                id={`intentionally_come_walk`}
                name={`intentionally_come_walk${item.id}`}
                value={1}
                checked={item?.intentionally_come_walk == 1}
                onChange={(event) => handleChange(item.id, event)}
              />

              <label className="radio-inline radio-class">Yes</label>

              <input
                type="radio"
                className="mr-1"
                id="intentionally_come_walk"
                name={`intentionally_come_walk${item.id}`}
                value={0}
                checked={item?.intentionally_come_walk == 0}
                onChange={(event) => handleChange(item.id, event)}
              />

              <label className="radio-inline">No</label>
            </div>
            <div className="col-md-8 offset-md-2 mb-30">
              <label htmlFor="txtName" className>
                Anything else you would like to share with us which will help
                us take better care of your doggy?
              </label>
              <textarea
                name="extra_instruction"
                className="form-control"
                id="extra_instruction"
                cols={30}
                value={item.extra_instruction}
                onChange={(event) => handleChange(item.id, event)}
                rows={4}
                defaultValue={""}
              />
              <p />
            </div>
          </div>
        </div>
        <div className="text-center">
          <p>For more than 1 dog , click ADD ANOTHER DOG </p>
          <p>For submitting this form , click SUBMIT</p>
          <p>“For deleting this form , click REMOVE FORM”</p>
        </div>

        <div className="text-center">
          <button
            type="button"
            id="add-more-rooms"
            className="main-btn font-24 mt-0 mb-30 login-form mt-5"
            onClick={() => removeForm(item.id)}
          >
            Remove Form
          </button>
        </div>
      </div>
    ));
  };


  return (
    <div>
      <section className="common-banner login-banner common-banner-fixed">
        <a href="index.php" className="white-logo">
          <h1>
            <img
              className="pt-16"
              src="https://www.petfelix.com/img/logo-white.png"
              alt="PETFELIX"
            />
          </h1>
        </a>
      </section>
      <div className="log-box login-box contact-bg single-sctn pb-50 mt--300">
        <div
          className="banner-query border-rad-10 box-sdw-black"
          id="form-wrapper"
        >
          <form method="POST" onSubmit={(e) => handleSubmit(e)}>
            <div className="specify-numbers-price">
              <div className="checkout-form light-font border-rad-10">
                <div className="form-booking">
                  <div className="text-center">
                    <h2 className="pb-4 f-24 ">Admission Form</h2>
                  </div>
                  <div className="d-flex mx-5 px-5">
                    <p style={{ color: "red" }}>* </p>
                    <p style={{ color: "#000" }}>
                      &nbsp;These fields are mandatory{" "}
                    </p>
                  </div>
                  <div className="col-md-8 offset-md-2 mb-30">
                    <label htmlFor>
                      Owners First Name
                      <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      id="first_name"
                      className="form-control"
                      placeholder=""
                      value={firstName}
                      required="required"
                      onChange={(e) => {
                        setFirstName(e.target.value);
                        setFirstNameError(e.target.value.trim() === "");
                      }}
                      name="owner_first_name"
                      type="text"
                    />
                    {firstNameError && (
                      <p className="text-orange">Required</p>
                    )}
                    <p />
                  </div>
                  <div className="col-md-8 offset-md-2 mb-30">
                    <label htmlFor>
                      Owners Last Name <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      id="last_name"
                      className="form-control"
                      required="required"
                      placeholder=""
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                        setLastNameError(e.target.value.trim() === "");
                      }}
                      name="owner_last_name"
                      type="text"
                    />
                    {!lastName && lastNameError && (
                      <p className="text-orange">Required</p>
                    )}
                    <p />
                  </div>
                  <div className="col-md-8 offset-md-2 mb-30">
                    <label htmlFor>
                      Contact No. <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      id="mobile"
                      className="form-control"
                      placeholder=""
                      minLength={10}
                      maxLength={10}
                      pattern="\d{10}"
                      value={mobile}
                      onChange={(e) => {
                        setMobile(e.target.value);
                        setMobileError(!e.target.validity.valid);
                      }}
                      required
                      name="mobile"
                      type="text"
                    />
                    {!mobile && mobileError && (
                      <p className="text-orange">Required</p>
                    )}
                    <p className="text-orange">
                      Please enter only the 10 digit mobile number - without
                      any prefix
                    </p>
                  </div>
                  <div className="col-md-8 offset-md-2 mb-30">
                    <label htmlFor>Address - Line 1 </label>
                    <input
                      id="address_line1"
                      className="form-control"
                      placeholder
                      value={street1}
                      onChange={(e) => setStreet1(e.target.value)}
                      name="street1"
                      type="text"
                    />
                    <p />
                  </div>
                  <div className="col-md-8 offset-md-2 mb-30">
                    <label htmlFor>Address - Line 2 </label>
                    <input
                      id="address_line2"
                      className="form-control"
                      placeholder
                      value={street2}
                      onChange={(e) => setStreet2(e.target.value)}
                      name="street2"
                      type="text"
                    />
                  </div>
                  <div className="col-md-8 offset-md-2 mb-30">
                    <label htmlFor>City </label>
                    <input
                      id="city"
                      className="form-control"
                      placeholder
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      name="city"
                      type="text"
                    />
                    <p />
                  </div>
                  <div className="col-md-8 offset-md-2 mb-30">
                    <label htmlFor>
                      Pincode<span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      id="pincode"
                      className="form-control"
                      minLength={6}
                      maxLength={6}
                      required
                      pattern="\d{6}"
                      value={pincode}
                      onChange={(e) => {
                        setPincodeError(e.target.value.trim() === "");
                        setPincode(e.target.value);
                      }}
                      name="pincode"
                      type="text"
                    />
                    {!pincode && pincodeError && (
                      <p className="text-orange">Required</p>
                    )}
                    <p />
                  </div>

                  {/* emg name */}

                  <div className="col-md-8 offset-md-2 mb-30 mt--10">
                    <label htmlFor="txtName" className>
                      Veterinary Doctor Name
                    </label>
                    <input
                      className="form-control"
                      name="doctor_name"
                      type="text"
                      value={doctor_name}
                      onChange={(event) => {
                        setDoctorName(event.target.value);
                        if (event.target.value.trim() === "") {
                          setDoctorNameError("Please enter the doctor name.");
                        } else {
                          setDoctorNameError("");
                        }
                      }}
                    />
                    {doctorNameError && (
                      <p className="text-orange">Required</p>
                    )}
                    <p />
                  </div>
                  <div className="col-md-8 offset-md-2 mb-30">
                    <label htmlFor="txtName" className>
                      Veterinary Doctor Number
                    </label>
                    <input
                      className="form-control"
                      name="doctor_number"
                      minLength={10}
                      value={doctor_number}
                      onChange={(event) => {
                        setDoctorNumber(event.target.value);
                        if (event.target.value.trim() === "") {
                          setDoctorNumberError(
                            "Please enter the doctor number"
                          );
                        } else {
                          setDoctorNumberError("");
                        }
                      }}
                      maxLength={10}
                      type="text"
                    />
                    {doctorNumberError && (
                      <p className="text-orange">Required</p>
                    )}
                    <p />
                    <p className="text-orange">
                      Your doggy's Veterinary doctor name and number
                    </p>
                  </div>

                  {/* dr number */}

                  <div className="col-md-8 offset-md-2 mb-30 mt--10">
                    <label htmlFor="txtName" className>
                      Emergency Person Name{" "}
                      <span className="text-danger">*</span>{" "}
                    </label>

                    <input
                      className="form-control"
                      name={`emg_contact`}
                      value={emg_contact}
                      required
                      onChange={(event) => {
                        setEmgContact(event.target.value);
                        if (event.target.value.trim() === "") {
                          setEmgContactError(
                            "Please enter the emg contact name "
                          );
                        } else {
                          setEmgContactError("");
                        }
                      }}
                      type="text"
                    />
                    {emgContactError && (
                      <p className="text-orange">Required</p>
                    )}
                    <p />
                  </div>

                  <div className="col-md-8 offset-md-2 mb-30">
                    <label htmlFor="txtName" className>
                      Emergency Person Contact Number{" "}
                      <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      className="form-control"
                      name={`emg_contact_no`}
                      type="text"
                      value={emg_contact_no}
                      required
                      onChange={(event) => {
                        setEmgContactNo(event.target.value);
                        if (event.target.value.trim() === "") {
                          setEmgContactNumberError(
                            "Please enter the emg contact name "
                          );
                        } else {
                          setEmgContactNumberError("");
                        }
                      }}
                      minLength={10}
                      maxLength={10}
                    />
                    {emgContactNumberError && (
                      <p className="text-orange">Required</p>
                    )}
                    <p />
                    <p className="text-orange">
                      Emergency contact name and number - should be a friend
                      or family member who would be in Mumbai while you are
                      away
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {renderForms()}

            <div className="text-center mb-30 mt-40">
              <button
                type="submit"
                className="main-btn font-24 mt-0 login-form"
                id="submit"
              >
                Submit
              </button>
            </div>
          </form>

          <div className="text-center">
            <button
              type="button"
              id="add-more-rooms"
              className="main-btn font-24 mt-0 mb-30 login-form "
              onClick={addForm}
            >
              Add Another Dog
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
